export const en = {
  assembledProduct: {
    addToAssembly: '',
    completeTheAssembly: '',
    removeFromAssembly: '',
    summary: {
      status: '',
      subtitle: '',
      title: '',
      total: '',
      yourAssembly: '',
    },
  },
  form: {
    newsletterForm: {
      buttons: {
        submit: {
          label: 'Subscribe',
        },
      },
      topics: {
        cat: 'Cat',
        dog: 'Dog',
      },
    },
    personalDataForm: {
      phoneNumbers: { label: 'Telephone' },
    },
  },
  misc: {
    PET_BREED: {
      cat: {
        options: {
          abissino: 'Abissino',
          americanCurl: 'American Curl',
          americanWirehair: 'American Wirehair',
          americanoAPeloCorto: 'Americano a pelo corto',
          angoraTurco: 'Angora turco',
          asian: 'Asian',
          australianMist: 'Australian Mist',
          balinese: 'Balinese',
          bengala: 'Bengala',
          bengalaLeopardoDelleNevi: 'Bengala-Leopardo delle nevi',
          birmano: 'Birmano',
          bluDiRussia: 'Blu di Russia',
          bobtailAmericano: 'Bobtail Americano',
          bombay: 'Bombay',
          britishLonghair: 'British Longhair',
          britishShorthair: 'British Shorthair',
          burmese: 'Burmese',
          burmilla: 'Burmilla',
          californiaSpangled: 'California Spangled',
          certosino: 'Certosino',
          chantilly: 'Chantilly (o Tiffany)',
          chausie: 'Chausie',
          chinchilla: 'Chinchilla',
          colorpointShorthair: 'Colorpoint Shorthair',
          cornishRex: 'Cornish Rex',
          cymric: 'Cymric',
          devonRex: 'Devon Rex',
          egyptianMau: 'Egyptian Mau',
          exoticShorthair: 'Exotic Shorthair',
          gattoDelleForesteNorvegesi: 'Gatto delle foreste norvegesi',
          gattoEuropeo: 'Gatto europeo',
          gattoNonDiRazza: 'Gatto non di razza',
          gattoSacroDiBirmania: 'Gatto Sacro di Birmania',
          german: 'German',
          germanRex: 'German Rex',
          havanaBrown: 'Havana Brown',
          himalayano: 'Himalayano',
          japaneseBobtailAPeloCorto: 'Japanese Bobtail a pelo corto',
          japaneseBobtailAPeloLungo: 'Japanese Bobtail a pelo lungo',
          khaoManee: 'Khao Manee',
          korat: 'Korat',
          kurilianBobtail: 'Kurilian Bobtail',
          laPermAPeloCorto: 'LaPerm a pelo corto',
          laPermAPeloLungo: 'LaPerm a pelo lungo',
          maineCoon: 'Maine Coon',
          manx: 'Manx',
          munchkin: 'Munchkin',
          nebelung: 'Nebelung',
          ocicat: 'Ocicat',
          orientaleAPeloCorto: 'Orientale a pelo corto',
          orientaleAPeloLungo: 'Orientale a pelo lungo',
          persianoAPeloLungo: 'Persiano a pelo lungo',
          peterbald: 'Peterbald',
          pixieBobAPeloCorto: 'Pixie Bob a pelo corto',
          pixieBobAPeloLungo: 'Pixie Bob a pelo lungo',
          ragamuffin: 'Ragamuffin',
          ragdoll: 'Ragdoll',
          savannah: 'Savannah',
          scottishFold: 'Scottish Fold',
          selectAnOption: 'Select an option',
          selkirkRexLonghair: 'Selkirk Rex Longhair',
          selkirkRexShorthair: 'Selkirk Rex Shorthair',
          siamese: 'Siamese',
          siberiano: 'Siberiano',
          singapura: 'Singapura',
          snowshoe: 'Snowshoe',
          sokoke: 'Sokoke',
          somalo: 'Somalo',
          sphynx: 'Sphynx',
          thai: 'Thai',
          tonkinese: 'Tonkinese',
          toyger: 'Toyger',
          turcoVan: 'Turco Van',
          yorkChocolate: 'York Chocolate',
        },
      },
      dog: {
        options: {
          affenpinscher: 'Affenpinscher',
          aidi: 'Aidi',
          ainuKen: 'Ainu-Ken',
          airedaleTerrier: 'Airedale terrier',
          akitaInu: 'Akita Inu',
          alano: 'Alano',
          alaskanMalamute: 'Alaskan Malamute',
          alpenlaendischeDachsbracke: 'Alpenlaendische Dachsbracke',
          altraRazza: 'Altra razza',
          americanStaffordshireTerrierr: 'American Staffordshire Terrierr',
          americanWaterSpaniel: 'American Water Spaniel',
          aneDaFermaTedesco: 'ane da Ferma Tedesco',
          aneDaMontagnaDeiPirenei: 'ane da Montagna dei Pirenei',
          ariegeois: 'Ariegeois',
          australianSilkyTerrier: 'Australian Silky Terrier',
          australianTerrier: 'Australian Terrier',
          azawakh: 'Azawakh',
          bBobtail: 'Bobtail',
          barbet: 'Barbet',
          barbone: 'Barbone',
          barboneNano: 'Barbone Nano',
          basenji: 'Basenji',
          bassetArtesienNormand: 'Basset Artesien Normand',
          bassetBleuDaGascogne: 'Basset Bleu da Gascogne',
          bassetFauveDaBretagne: 'Basset Fauve da Bretagne',
          bassetGriffonVendeen: 'Basset Griffon Vendeen',
          bassetHound: 'Basset-Hound',
          bassotto: 'Bassotto',
          beagle: 'Beagle',
          beardedCollie: 'Bearded Collie',
          beauceron: 'Beauceron',
          bedlingtonTerrier: 'Bedlington Terrier',
          bichonAvanese: 'Bichon Avanese',
          bichonFrise: 'Bichon Frise',
          billy: 'Billy',
          blackAndTanCoonhound: 'Black and Tan Coonhound',
          blackAndTanToyTerrier: 'Black and Tan Toy Terrier',
          bleuDeGascogne: 'Bleu de gascogne',
          bloodhoundChienDeStHubert: 'Bloodhound - Chien de St. Hubert',
          bluetickCoonhound: 'Bluetick Coonhound',
          bolognese: 'Bolognese',
          borderCollie: 'Border Collie',
          borderTerrier: 'Border Terrier',
          borzoi: 'Borzoi',
          bostonTerrier: 'Boston Terrier',
          bouledogueFrancese: 'Bouledogue Francese',
          bovaroDelleArdenne: 'Bovaro delle Ardenne',
          bovaroDelleFiandre: 'Bovaro delle Fiandre',
          bovaroSvizzero: 'Bovaro svizzero',
          boxer: 'Boxer',
          boykinSpaniel: 'Boykin spaniel',
          brabantino: 'Brabantino',
          braccoDiBurgos: 'Bracco di Burgos',
          braccoFrancese: 'Bracco francese',
          braccoItaliano: 'Bracco Italiano',
          braccoTedesco: 'Bracco tedesco',
          braccoUngherese: 'Bracco Ungherese',
          broholmer: 'Broholmer',
          bullTerrier: 'Bull Terrier',
          bulldogAmericano: 'Bulldog americano',
          bulldogInglese: 'Bulldog Inglese',
          bullmastiff: 'Bullmastiff',
          cairnTerrier: 'Cairn Terrier',
          canaanDog: 'Canaan Dog',
          caneDaFermaBoemo: 'Cane da Ferma Boemo',
          caneDaFermaSlovacco: 'Cane da Ferma Slovacco',
          caneDaOrsoDellaCarelia: 'Cane da Orso della Carelia',
          caneDeiPirenei: 'Cane dei pirenei',
          caneDellAtlas: "Cane dell'Atlas",
          caneDellaSerraDiEstrela: 'Cane della Serra di Estrela',
          caneDiCastroLaboreiro: 'Cane di Castro Laboreiro',
          caneImperialeCinese: 'Cane imperiale cinese',
          caneLupoCecoslovacco: 'Cane Lupo Cecoslovacco',
          caneLupoDiSaarloos: 'Cane Lupo di Saarloos',
          caneNonDiRazza: 'Cane non di razza',
          caneNudo: 'Cane Nudo',
          caneTosa: 'Cane Tosa',
          caoDeAguaPortugues: 'Cao de Agua Portugues',
          caoDeCastroLaboreiro: 'Cao de castro laboreiro',
          caoDeSerraDaEstrela: 'Cao de serra da estrela',
          caoDeSerraDeAires: 'Cao de Serra de Aires',
          carlino: 'Carlino',
          catahoulaLeopardDog: 'Catahoula leopard dog',
          cattleDog: 'Cattle Dog',
          cavalierKingCharlesSpaniel: 'Cavalier King Charles Spaniel',
          ceskyFousek: 'Cesky fousek',
          ceskyTerrier: 'Cesky Terrier',
          chartPolsky: 'Chart polsky',
          chesapeakeBayRetriever: 'Chesapeake Bay Retriever',
          chienDArtois: "Chien d'Artois",
          chienFranAsais: 'Chien FranÃ§ais',
          chienFrancais: 'Chien francais',
          chihuahua: 'Chihuahua',
          chineseCrestedDog: 'Chinese Crested Dog',
          chineseTempleDog: 'Chinese Temple Dog',
          chowChow: 'Chow Chow',
          cirnecoDellEtna: "Cirneco dell'Etna",
          clumberSpaniel: 'Clumber Spaniel',
          cockerSpaniel: 'Cocker Spaniel',
          coonhound: 'Coonhound',
          corgiGallese: 'Corgi gallese',
          corso: 'Corso',
          cotonDeTulear: 'Coton de Tulear',
          curlyCoatedRetriever: 'Curly-Coated Retriever',
          dalmata: 'Dalmata',
          dandieDinmontTerrier: 'Dandie Dinmont Terrier',
          deerhound: 'Deerhound',
          deutscherJagdTerrier: 'Deutscher Jagd Terrier',
          dobermann: 'Dobermann',
          dogoArgentino: 'Dogo Argentino',
          dogueDeBordeaux: 'Dogue de Bordeaux',
          drahthaar: 'Drahthaar',
          drentsePatrijshond: 'Drentse Patrijshond',
          drever: 'Drever',
          dunker: 'Dunker',
          epagneulFrancese: 'Epagneul francese',
          epagneulNanoContinentale: 'Epagneul Nano Continentale',
          erdelyiKopo: 'Erdelyi kopo',
          esquimeseEskimoDog: 'Esquimese - eskimo dog',
          eurasier: 'Eurasier',
          fieldSpaniel: 'Field Spaniel',
          filaBrasileiro: 'Fila Brasileiro',
          flatCoatedRetriever: 'Flat-Coated Retriever',
          foxTerrier: 'Fox Terrier',
          foxhound: 'Foxhound',
          gammelDanskHonssehund: 'Gammel dansk honssehund',
          germanSpaniel: 'German Spaniel',
          glenOfImaalTerrier: 'Glen of Imaal Terrier',
          goldenRetriever: 'Golden Retriever',
          grahund: 'Grahund',
          grandAngloFrancais: 'Grand Anglo-Francais',
          grandBleuDeGascogne: 'Grand Bleu de Gascogne',
          grandGascogneSaintongeois: 'Grand Gascogne-Saintongeois',
          grausterMunsterlander: 'Grauster munsterlander',
          griffoncino: 'Griffoncino',
          griffone: 'Griffone',
          groenlandese: 'Groenlandese',
          haldenstover: 'Haldenstover',
          hamiltonstovare: 'Hamiltonstovare',
          harlekinpinscher: 'Harlekinpinscher',
          harrierDelSomerset: 'Harrier del somerset',
          hokkaidoken: 'Hokkaidoken',
          hovawart: 'Hovawart',
          hygenhund: 'Hygenhund',
          icelandDog: 'Iceland dog',
          icelandSheepdog: 'Iceland Sheepdog',
          irishTerrier: 'Irish terrier',
          irishWaterSpaniel: 'Irish water spaniel',
          irishWolfhound: 'Irish wolfhound',
          jamthund: 'Jamthund',
          juraLaufhund: 'Jura laufhund',
          keeshond: 'Keeshond',
          kelpie: 'Kelpie',
          komondor: 'Komondor',
          kromfohrlander: 'Kromfohrlander',
          kurzhaar: 'Kurzhaar',
          kuvasz: 'Kuvasz',
          kyushu: 'Kyushu',
          labradorRetriever: 'Labrador Retriever',
          lagottoRomagnolo: 'Lagotto Romagnolo',
          laicaDellaSiberia: 'Laica della siberia',
          lakelandTerrier: 'Lakeland Terrier',
          landseer: 'Landseer',
          langhaar: 'Langhaar',
          lapinporokoira: 'Lapinporokoira',
          lapphund: 'Lapphund',
          leonberger: 'Leonberger',
          levesque: 'Levesque',
          levriero: 'Levriero',
          lhasaApso: 'Lhasa apso',
          lunderhund: 'Lunderhund',
          magyarAgar: 'Magyar agar',
          maltese: 'Maltese',
          manchesterTerrier: 'Manchester terrier',
          mastiff: 'Mastiff',
          mastinoNapoletano: 'Mastino Napoletano',
          mastinoSpagnolo: 'Mastino spagnolo',
          mudi: 'Mudi',
          norfolkTerrier: 'Norfolk Terrier',
          norrbottenspets: 'Norrbottenspets',
          norskBuhund: 'Norsk buhund',
          norskElghund: 'Norsk elghund',
          norwichTerrier: 'Norwich Terrier',
          ogarPolski: 'Ogar polski',
          otterhound: 'Otterhound',
          pastoreAustraliano: 'Pastore Australiano',
          pastoreBelga: 'Pastore Belga',
          pastoreBergamasco: 'Pastore Bergamasco',
          pastoreCatalano: 'Pastore Catalano',
          pastoreCroato: 'Pastore Croato',
          pastoreDeiPirenei: 'Pastore dei Pirenei',
          pastoreDeiTatra: 'Pastore dei Tatra',
          pastoreDelCaucaso: 'Pastore del Caucaso',
          pastoreDellAnatolia: "Pastore dell'Anatolia",
          pastoreDellAsiaCentrale: "Pastore dell'Asia Centrale",
          pastoreDellaBrie: 'Pastore della Brie',
          pastoreDellaPiccardia: 'Pastore della Piccardia',
          pastoreDelleShetland: 'Pastore delle Shetland',
          pastoreDiKarst: 'Pastore di Karst',
          pastoreDiPiccardia: 'Pastore di piccardia',
          pastoreDiTatra: 'Pastore di tatra',
          pastoreDiVallee: 'Pastore di Vallee',
          pastoreItaliano: 'Pastore italiano',
          pastoreMallorquin: 'Pastore Mallorquin',
          pastoreMaremmanoAbruzzese: 'Pastore Maremmano-Abruzzese',
          pastoreOlandese: 'Pastore Olandese',
          pastoreScozzese: 'Pastore Scozzese',
          pastoreSvizzeroBianco: 'Pastore svizzero bianco',
          pastoreTedesco: 'Pastore Tedesco',
          pechinese: 'Pechinese',
          perdiguero: 'Perdiguero',
          petitAngloFrancais: 'Petit anglo francais',
          pharaonHound: 'Pharaon Hound',
          piccoloCaneLeone: 'Piccolo Cane Leone',
          pinscher: 'Pinscher',
          plottHound: 'Plott Hound',
          podenco: 'Podenco',
          podengo: 'Podengo',
          pointer: 'Pointer',
          poitevin: 'Poitevin',
          porcelaine: 'Porcelaine',
          pudelpointer: 'Pudelpointer',
          puli: 'Puli',
          pumi: 'Pumi',
          rafeiroDoAlentejo: 'Rafeiro do alentejo',
          rastreadorBrasileiro: 'Rastreador brasileiro',
          reyhound: 'reyhound',
          rhodesianRidgeback: 'Rhodesian ridgeback',
          rottweiler: 'Rottweiler',
          sabuesoEsanol: 'Sabueso esanol',
          samoiedo: 'Samoiedo',
          sanBernardo: 'San Bernardo',
          sanshu: 'Sanshu',
          sarplaninac: 'Sarplaninac',
          schapendoes: 'Schapendoes',
          schillerstovare: 'Schillerstovare',
          schipperkee: 'Schipperkee',
          schnauzer: 'Schnauzer',
          schweizerLaufhund: 'Schweizer laufhund',
          scottishTerrier: 'Scottish Terrier',
          sealyhamTerrier: 'Sealyham terrier',
          segugio: 'Segugio',
          selectAnOption: 'Select an option',
          setterGordon: 'Setter gordon',
          setterIrlandese: 'Setter irlandese',
          sharPei: 'Shar Pei',
          shetlandSheepdog: 'Shetland Sheepdog',
          shibaInu: 'Shiba Inu',
          shihTzu: 'Shih-Tzu',
          siberianHusky: 'Siberian Husky',
          silkyTerrier: 'Silky Terrier',
          skyeTerrier: 'Skye Terrier',
          slovenskyKopov: 'Slovensky Kopov',
          smalandsstovare: 'Smalandsstovare',
          softCoatedWheatenTerrier: 'Soft coated wheaten terrier',
          spanielGiapponeseTchin: 'Spaniel giapponese - tchin',
          spanielTedesco: 'Spaniel tedesco',
          spinoneItaliano: 'Spinone Italiano',
          spitz: 'Spitz',
          stabyhound: 'Stabyhound',
          staffordshireBullTerrier: 'Staffordshire Bull Terrier',
          steinbracke: 'Steinbracke',
          stichelhaar: 'Stichelhaar',
          sussexSpaniel: 'Sussex spaniel',
          tchiornyTerrierTerrierNeroRusso: 'Tchiorny terrier - terrier nero russo',
          tchouvatchSlovacco: 'Tchouvatch slovacco',
          telomian: 'Telomian',
          tennesseeTreeingBrindle: 'Tennessee Treeing Brindle',
          terranova: 'Terranova',
          terrierBrasileiro: 'Terrier brasileiro',
          terrierGiapponese: 'Terrier Giapponese',
          thailandRidgeback: 'Thailand Ridgeback',
          tibetanMastiff: 'Tibetan Mastiff',
          tibetanSpaniel: 'Tibetan spaniel',
          tibetanTerrier: 'Tibetan terrier',
          tirolerBracke: 'Tiroler bracke',
          toyTerrier: 'Toy terrier',
          treeingWalkerCoonhound: 'Treeing Walker Coonhound',
          triggHound: 'Trigg Hound',
          vastgotaspets: 'Vastgotaspets',
          viszla: 'Viszla',
          volpino: 'Volpino',
          volpinoFinlandese: 'Volpino finlandese',
          wachtelhund: 'Wachtelhund',
          weimaraner: 'Weimaraner',
          welshTerrier: 'Welsh Terrier',
          westHighlandWhiteTerrier: 'West highland white terrier',
          wetterhounSpanielOlandese: 'Wetterhoun - spaniel olandese',
          whippet: 'Whippet',
          wolfspitz: 'Wolfspitz',
          yorkshireTerrier: 'Yorkshire Terrier',
          zwergpinscherPinscherNano: 'Zwergpinscher - Pinscher nano',
        },
      },
      label: '',
    },
    PET_FUR_TYPE: {
      label: '',
      options: {
        long: '',
        short: '',
      },
    },
    PET_INDOOR: {
      label: '',
      options: {
        indoor: '',
        outdoor: '',
      },
    },
    PET_NEUTERED: {
      label: '',
      options: {
        no: '',
        yes: '',
      },
    },
    PET_NUTRITION: {
      label: '',
      options: {
        both: '',
        dry: '',
        other: '',
        selectAnOption: 'Select an option',
        wet: '',
      },
    },
    PET_SIZE_CODE: {
      label: '',
      options: {
        large: '',
        medium: '',
        selectAnOption: 'Select an option',
        small: '',
      },
    },
    PET_TYPE_CODE: {
      label: '',
      options: {
        cat: '',
        dog: '',
      },
    },
    PET_WET_FOOD_BRAND: {
      label: '',
      options: {
        adventuros: 'Adventuros',
        beyond: 'Beyond',
        catChow: 'CatChow',
        deliBakie: 'DeliBakie',
        dentalife: 'Dentalife',
        felix: 'Felix',
        friskies: 'Friskies',
        gourmet: 'Gourmet',
        purinaOne: 'Purina ONE',
        purinaProPlan: 'Purina PRO PLAN',
        purinaProPlanVeterinariDiets: 'Purina PRO PLAN Veterinari Diets',
        selectAnOption: 'Select an option',
        tonusDogChow: 'Tonus Dog Chow',
      },
    },
    account: 'Account',
    additionalProperties: {
      EXTERNAL_VET_ID: '',
      OCCUPATION_VET: '',
      PROVINCE_VET: '',
    },
    availabilityEmailNotification: 'Notify me',
    blog: {
      allArticles: 'All',
      readArticle: 'Read article',
    },
    checkoutVetCompleteCompanyAlert: '',
    customerForm: {
      registrationSuccessful: {
        modal: {
          title: 'Registration successful',
        },
      },
    },
    employee: {
      company: {
        label: '',
        options: {
          nespresso: 'Nespresso Italiana S.p.A.',
          nestle: 'Nestlé Italiana S.p.A.',
          nestleShop: 'Nestlé Shop S.r.l.',
          ngs: 'Nestlé Global Services Italy S.r.l.',
          purina: 'Nestlé Purina Commerciale S.r.l.',
          sanpellegrino: 'Sanpellegrino S.p.A.',
          selectAnOption: 'Select an option',
        },
      },
      email: { label: '', placeholder: '' },
      externalId: { label: '', placeholder: '' },
    },
    greenNumber: 'Green number',
    size: '',
    size_one: 'Variant',
    size_other: 'Variants',
    storeName: 'Purina',
    vet: {
      externalId: { label: '', placeholder: '' },
      occupation: {
        label: '',
        options: {
          altro: '',
          hoUnaStruttura: '',
          lavoroPressoUnaStruttura: '',
          selectAnOption: 'Select an option',
          sonoUnFreeLance: '',
        },
      },
      province: { label: '', placeholder: '' },
    },
  },
  product: {
    code: 'Code',
    gallery: {
      zoom: 'Click on image to enlarge',
    },
  },
  welcome: 'Welcome to Purina',
};
